<template>
  <b-card>
    <g-form @submit="save">
      <b-row>
        <b-col md="3">
          <!-- code  -->
          <g-field
            id="code"
            :value.sync="selectedItem.code"
            label-text="code"
            name="code"
          />
        </b-col>
        <b-col md="3">
          <!-- name  -->
          <g-field
            id="name"
            :value.sync="selectedItem.name"
            label-text="name"
            rules="required"
            name="name"
          />
        </b-col>
        <!-- maxCountOfUsage -->
        <b-col md="3">
          <g-field
            label-text="maxCountOfUsage"
            field="number"
            name="maxCountOfUsage"
            rules="integer|required"
            :value.sync="selectedItem.maxCountOfUsage"
            type="number"
          />
        </b-col>
        <!-- percentage -->
        <b-col md="3">
          <g-field
            label-text="percentage"
            field="number"
            rules="required||min_value:0|max_value:100"
            :value.sync="selectedItem.percentage"
            type="number"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <g-picker
            :value.sync="selectedItem.creationDateUtc"
            label-text="creationDate"
            disabled
          />
        </b-col>
        <b-col md="3">
          <g-picker :value.sync="selectedItem.startDate" label-text="startDate" />
        </b-col>
        <b-col md="3">
          <g-picker :value.sync="selectedItem.endDate" label-text="endDate" />
        </b-col>
        <b-col md="3">
          <b-form-group class="d-inline" :label="$t('isActive')">
            <b-form-checkbox
              v-model="selectedItem.isActive"
              class="mr-0 mt-50"
              name="is-rtl"
              inline
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            class="mx-1"
            type="submit"
            variant="primary"
            data-action-type="save"
          >
            {{ $t('save') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      selectedItem: {
        code: '',
        name: '',
        percentage: 0,
        createdBy: 0,
        startDate: this.today,
        endDate: this.today,
        maxCountOfUsage: 0,
        isActive: true,
        creationDateUtc: this.today,
      },
      profile: JSON.parse(window.localStorage.getItem('@USER_PROFILE')),
    };
  },
  computed: {},
  mounted() {
    if (this.id > 0) {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.get({ url: 'DiscountCode', id: this.id }).then((data) => {
        this.selectedItem = data;
      });
    },
    save(type) {
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'DiscountCode',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.$router.push({ name: 'discount-code' });
        });
      } else {
        this.selectedItem.createdBy = this.profile.userId;
        this.create({
          url: 'DiscountCode',
          data: this.selectedItem,
        }).then((data) => {
          this.doneAlert({
            text: this.$t('savedSuccessfully'),
          });
          this.$router.push({ name: 'discount-code' });
        });
      }
    },
  },
};
</script>

<style></style>
